/********** Add Your Global CSS Here **********/
h1,
h2,
h3,
h4,
h5,
h6,
a,
p,
li,
input,
textarea,
span,
div,
html,
body,
html a {
  margin-bottom: 0;
  font-family: Arial,Helvetica,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
}

.scrollbar-track-y,
.scrollbar-thumb-y {
  width: 5px !important;
}

.scrollbar-track-x,
.scrollbar-thumb-x {
  height: 5px !important;
}

.scrollbar-thumb {
  border-radius: 0 !important;
}

.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow:after,
.ant-popover-placement-bottomLeft
  > .ant-popover-content
  > .ant-popover-arrow:after,
.ant-popover-placement-bottomRight
  > .ant-popover-content
  > .ant-popover-arrow:after,
.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow:after,
.ant-popover-placement-topLeft
  > .ant-popover-content
  > .ant-popover-arrow:after,
.ant-popover-placement-topRight
  > .ant-popover-content
  > .ant-popover-arrow:after {
  left: 0;
  margin-left: -4px;
}

/********** Add Your Global RTL CSS Here **********/
/* Popover */
html[dir='rtl'] .ant-popover {
  text-align: right;
}

/* Ecommerce Card */
html[dir='rtl'] .isoCardInfoForm .ant-input {
  text-align: right;
}

/* Modal */
html[dir='rtl'] .has-success.has-feedback:after,
html[dir='rtl'] .has-warning.has-feedback:after,
html[dir='rtl'] .has-error.has-feedback:after,
html[dir='rtl'] .is-validating.has-feedback:after {
  left: 0;
  right: auto;
}

html[dir='rtl'] .ant-modal-close {
  right: inherit;
  left: 0;
}

html[dir='rtl'] .ant-modal-footer {
  text-align: left;
}

html[dir='rtl'] .ant-modal-footer button + button {
  margin-left: 0;
  margin-right: 8px;
}

html[dir='rtl'] .ant-confirm-body .ant-confirm-content {
  margin-right: 42px;
}

html[dir='rtl'] .ant-btn > .anticon + span,
html[dir='rtl'] .ant-btn > span + .anticon {
  margin-right: 0.5em;
}

html[dir='rtl'] .ant-btn-loading span {
  margin-left: 0;
  margin-right: 0.5em;
}

html[dir='rtl']
  .ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline) {
  padding-left: 25px;
  padding-right: 29px;
}

html[dir='rtl']
  .ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline)
  .anticon {
  margin-right: -14px;
  margin-left: 0;
}

/* Confirm */
html[dir='rtl'] .ant-modal.ant-confirm .ant-confirm-body > .anticon {
  margin-left: 16px;
  margin-right: 0;
  float: right;
}

html[dir='rtl'] .ant-modal.ant-confirm .ant-confirm-btns {
  float: left;
}

html[dir='rtl'] .ant-modal.ant-confirm .ant-confirm-btns button + button {
  margin-right: 10px;
  margin-left: 0;
}

/* Message */
html[dir='rtl'] .ant-message .anticon {
  margin-left: 8px;
  margin-right: 0;
}

/* Pop Confirm */
html[dir='rtl'] .ant-popover-message-title {
  padding-right: 20px;
  padding-left: 0;
}

html[dir='rtl'] .ant-popover-buttons {
  text-align: left;
}

/* Notification */
html[dir='rtl']
  .ant-notification-notice-closable
  .ant-notification-notice-message {
  padding-left: 24px;
  padding-right: 0;
}

html[dir='rtl']
  .ant-notification-notice-with-icon
  .ant-notification-notice-message,
html[dir='rtl']
  .ant-notification-notice-with-icon
  .ant-notification-notice-description {
  margin-right: 48px;
}

html[dir='rtl'] .ant-notification-notice-close {
  right: auto;
  left: 16px;
}

html[dir='rtl'] .ant-notification-notice-with-icon {
  left: 0;
}

/* Dropzone */
html[dir='rtl'] .dz-hidden-input {
  display: none;
}

.ant-message {
  top: 70px !important;
}


.spin-loading {
  position: fixed;
  z-index: 999;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 50px;
}
    
.container {
  height: 100vh;
  width: 100vw;
  font-family: Helvetica;
}

.loader {
  width: 250px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.loader--dot {
  -webkit-animation-name: loader;
          animation-name: loader;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
  -webkit-animation-duration: 2.5s;
          animation-duration: 2.5s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  height: 20px;
  width: 20px;
  border-radius: 100%;
  background-color: black;
  position: absolute;
  border: 2px solid white;
}
.loader--dot:first-child {
  background-color: #8cc759;
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
}
.loader--dot:nth-child(2) {
  background-color: #8c6daf;
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
}
.loader--dot:nth-child(3) {
  background-color: #ef5d74;
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
}
.loader--dot:nth-child(4) {
  background-color: #f9a74b;
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
}
.loader--dot:nth-child(5) {
  background-color: #60beeb;
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s;
}
.loader--dot:nth-child(6) {
  background-color: #fbef5a;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}
.loader--text {
  position: absolute;
  top: 200%;
  left: 0;
  right: 0;
  width: 4rem;
  margin: auto;
}
.loader--text:after {
  content: "Loading";
  font-weight: bold;
  -webkit-animation-name: loading-text;
          animation-name: loading-text;
  -webkit-animation-duration: 3s;
          animation-duration: 3s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}

@-webkit-keyframes loader {
  15% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  45% {
    -webkit-transform: translateX(230px);
            transform: translateX(230px);
  }
  65% {
    -webkit-transform: translateX(230px);
            transform: translateX(230px);
  }
  95% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@keyframes loader {
  15% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  45% {
    -webkit-transform: translateX(230px);
            transform: translateX(230px);
  }
  65% {
    -webkit-transform: translateX(230px);
            transform: translateX(230px);
  }
  95% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
@-webkit-keyframes loading-text {
  0% {
    content: "Loading";
  }
  25% {
    content: "Loading.";
  }
  50% {
    content: "Loading..";
  }
  75% {
    content: "Loading...";
  }
}
@keyframes loading-text {
  0% {
    content: "Loading";
  }
  25% {
    content: "Loading.";
  }
  50% {
    content: "Loading..";
  }
  75% {
    content: "Loading...";
  }
}


.ant-select-lg .ant-select-selection{

  height: 42px !important;
}

.ant-select-lg .ant-select-selection .ant-select-selection__rendered {
  
  line-height: 39px !important;
}



.version-upd-notif .ant-modal-wrap {
  top: unset;
}

.version-upd-notif .ant-modal {
  top: unset;
  bottom: 0;
}

.version-upd-notif .ant-modal-content {
  border-radius: 15px;
  text-align: center;
}

.version-upd-notif .font-18{
  font-size: 18px;
}

.version-upd-notif img{
  width: 150px;
}


.version-upd-notif .width100 button{
  width: 100%;
}

.version-upd-notif .bottom-btn {
  border-radius: 10px;
  text-transform: capitalize !important;
  height: 55px !important;
  font-size: 16px !important;
  font-weight: bold !important;
}


.version-upd-notif .bottom-btn.green-btn {
  background: #56b46c;
  color: #FCFCFC;
}


.version-popup-notif {

  position: fixed;
  bottom: 20px;    
  right: 10px;
  left: 10px;
  z-index: 999;
  padding: 15px 20px 10px;
  border: #efefef;
  border-radius: 10px;
  background: #e9e9e9e0;
  /* background: #efefefcf; */

  svg {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }

  img {
    
    width: 50px;
    height: 50px;
  }

  .align-middle { 
    display: flex;
    align-items: center;
    justify-content: center;
  }

}
.align-baseline {
	vertical-align: baseline !important;
}

.align-top {
	vertical-align: top !important;
}

.align-middle {
	vertical-align: middle !important;
}

.align-bottom {
	vertical-align: bottom !important;
}

.align-text-bottom {
	vertical-align: text-bottom !important;
}

.align-text-top {
	vertical-align: text-top !important;
}

.bg-none {
	background-color: none !important;
}

.bg-faded {
	background-color: #f7f7f7;
}

.bg-primary {
	background-color: #0275d8 !important;
}

a.bg-primary:focus, a.bg-primary:hover {
	background-color: #025aa5 !important;
}

.bg-success {
	background-color: #5cb85c !important;
}

a.bg-success:focus, a.bg-success:hover {
	background-color: #449d44 !important;
}

.bg-info {
	background-color: #5bc0de !important;
}

a.bg-info:focus, a.bg-info:hover {
	background-color: #31b0d5 !important;
}

.bg-warning {
	background-color: #f0ad4e !important;
}

a.bg-warning:focus, a.bg-warning:hover {
	background-color: #ec971f !important;
}

.bg-danger {
	background-color: #d9534f !important;
}

a.bg-danger:focus, a.bg-danger:hover {
	background-color: #c9302c !important;
}

.bg-inverse {
	background-color: #292b2c !important;
}

a.bg-inverse:focus, a.bg-inverse:hover {
	background-color: #101112 !important;
}

.border-0 {
	border: 0 !important;
}

.border-top-0 {
	border-top: 0 !important;
}

.border-right-0 {
	border-right: 0 !important;
}

.border-bottom-0 {
	border-bottom: 0 !important;
}

.border-left-0 {
	border-left: 0 !important;
}

.rounded {
	border-radius: 0.25rem;
}

.rounded-top {
	border-top-right-radius: 0.25rem;
	border-top-left-radius: 0.25rem;
}

.rounded-right {
	border-bottom-right-radius: 0.25rem;
	border-top-right-radius: 0.25rem;
}

.rounded-bottom {
	border-bottom-right-radius: 0.25rem;
	border-bottom-left-radius: 0.25rem;
}

.rounded-left {
	border-bottom-left-radius: 0.25rem;
	border-top-left-radius: 0.25rem;
}

.rounded-circle {
	border-radius: 50%;
}

.rounded-0 {
	border-radius: 0;
}

.clearfix::after {
	display: block;
	content: "";
	clear: both;
}

.d-none {
	display: none !important;
}

.d-inline {
	display: inline !important;
}

.d-inline-block {
	display: inline-block !important;
}

.d-block {
	display: block !important;
}

.d-table {
	display: table !important;
}

.d-table-cell {
	display: table-cell !important;
}

.d-flex {
	display: flex !important;
}

.d-inline-flex {
	display: inline-flex !important;
}

@media (min-width: 576px) {
	.d-sm-none {
		display: none !important;
	}
	.d-sm-inline {
		display: inline !important;
	}
	.d-sm-inline-block {
		display: inline-block !important;
	}
	.d-sm-block {
		display: block !important;
	}
	.d-sm-table {
		display: table !important;
	}
	.d-sm-table-cell {
		display: table-cell !important;
	}
	.d-sm-flex {
		display: flex !important;
	}
	.d-sm-inline-flex {
		display: inline-flex !important;
	}
}

@media (min-width: 768px) {
	.d-md-none {
		display: none !important;
	}
	.d-md-inline {
		display: inline !important;
	}
	.d-md-inline-block {
		display: inline-block !important;
	}
	.d-md-block {
		display: block !important;
	}
	.d-md-table {
		display: table !important;
	}
	.d-md-table-cell {
		display: table-cell !important;
	}
	.d-md-flex {
		display: flex !important;
	}
	.d-md-inline-flex {
		display: inline-flex !important;
	}
}

@media (min-width: 992px) {
	.d-lg-none {
		display: none !important;
	}
	.d-lg-inline {
		display: inline !important;
	}
	.d-lg-inline-block {
		display: inline-block !important;
	}
	.d-lg-block {
		display: block !important;
	}
	.d-lg-table {
		display: table !important;
	}
	.d-lg-table-cell {
		display: table-cell !important;
	}
	.d-lg-flex {
		display: flex !important;
	}
	.d-lg-inline-flex {
		display: inline-flex !important;
	}
}

@media (min-width: 1200px) {
	.d-xl-none {
		display: none !important;
	}
	.d-xl-inline {
		display: inline !important;
	}
	.d-xl-inline-block {
		display: inline-block !important;
	}
	.d-xl-block {
		display: block !important;
	}
	.d-xl-table {
		display: table !important;
	}
	.d-xl-table-cell {
		display: table-cell !important;
	}
	.d-xl-flex {
		display: flex !important;
	}
	.d-xl-inline-flex {
		display: inline-flex !important;
	}
}

.flex-first {
	order: -1;
}

.flex-last {
	order: 1;
}

.flex-unordered {
	order: 0;
}

.flex-row {
	flex-direction: row !important;
}

.flex-column {
	flex-direction: column !important;
}

.flex-row-reverse {
	flex-direction: row-reverse !important;
}

.flex-column-reverse {
	flex-direction: column-reverse !important;
}

.flex-wrap {
	flex-wrap: wrap !important;
}

.flex-nowrap {
	flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
	flex-wrap: wrap-reverse !important;
}

.justify-content-start {
	justify-content: flex-start !important;
}

.justify-content-end {
	justify-content: flex-end !important;
}

.justify-content-center {
	justify-content: center !important;
}

.justify-content-between {
	justify-content: space-between !important;
}

.justify-content-around {
	justify-content: space-around !important;
}

.align-items-start {
	align-items: flex-start !important;
}

.align-items-end {
	align-items: flex-end !important;
}

.align-items-center {
	align-items: center !important;
}

.align-items-baseline {
	align-items: baseline !important;
}

.align-items-stretch {
	align-items: stretch !important;
}

.align-content-start {
	align-content: flex-start !important;
}

.align-content-end {
	align-content: flex-end !important;
}

.align-content-center {
	align-content: center !important;
}

.align-content-between {
	align-content: space-between !important;
}

.align-content-around {
	align-content: space-around !important;
}

.align-content-stretch {
	align-content: stretch !important;
}

.align-self-auto {
	align-self: auto !important;
}

.align-self-start {
	align-self: flex-start !important;
}

.align-self-end {
	align-self: flex-end !important;
}

.align-self-center {
	align-self: center !important;
}

.align-self-baseline {
	align-self: baseline !important;
}

.align-self-stretch {
	align-self: stretch !important;
}

@media (min-width: 576px) {
	.flex-sm-first {
		order: -1;
	}
	.flex-sm-last {
		order: 1;
	}
	.flex-sm-unordered {
		order: 0;
	}
	.flex-sm-row {
		flex-direction: row !important;
	}
	.flex-sm-column {
		flex-direction: column !important;
	}
	.flex-sm-row-reverse {
		flex-direction: row-reverse !important;
	}
	.flex-sm-column-reverse {
		flex-direction: column-reverse !important;
	}
	.flex-sm-wrap {
		flex-wrap: wrap !important;
	}
	.flex-sm-nowrap {
		flex-wrap: nowrap !important;
	}
	.flex-sm-wrap-reverse {
		flex-wrap: wrap-reverse !important;
	}
	.justify-content-sm-start {
		justify-content: flex-start !important;
	}
	.justify-content-sm-end {
		justify-content: flex-end !important;
	}
	.justify-content-sm-center {
		justify-content: center !important;
	}
	.justify-content-sm-between {
		justify-content: space-between !important;
	}
	.justify-content-sm-around {
		justify-content: space-around !important;
	}
	.align-items-sm-start {
		align-items: flex-start !important;
	}
	.align-items-sm-end {
		align-items: flex-end !important;
	}
	.align-items-sm-center {
		align-items: center !important;
	}
	.align-items-sm-baseline {
		align-items: baseline !important;
	}
	.align-items-sm-stretch {
		align-items: stretch !important;
	}
	.align-content-sm-start {
		align-content: flex-start !important;
	}
	.align-content-sm-end {
		align-content: flex-end !important;
	}
	.align-content-sm-center {
		align-content: center !important;
	}
	.align-content-sm-between {
		align-content: space-between !important;
	}
	.align-content-sm-around {
		align-content: space-around !important;
	}
	.align-content-sm-stretch {
		align-content: stretch !important;
	}
	.align-self-sm-auto {
		align-self: auto !important;
	}
	.align-self-sm-start {
		align-self: flex-start !important;
	}
	.align-self-sm-end {
		align-self: flex-end !important;
	}
	.align-self-sm-center {
		align-self: center !important;
	}
	.align-self-sm-baseline {
		align-self: baseline !important;
	}
	.align-self-sm-stretch {
		align-self: stretch !important;
	}
}

@media (min-width: 768px) {
	.flex-md-first {
		order: -1;
	}
	.flex-md-last {
		order: 1;
	}
	.flex-md-unordered {
		order: 0;
	}
	.flex-md-row {
		flex-direction: row !important;
	}
	.flex-md-column {
		flex-direction: column !important;
	}
	.flex-md-row-reverse {
		flex-direction: row-reverse !important;
	}
	.flex-md-column-reverse {
		flex-direction: column-reverse !important;
	}
	.flex-md-wrap {
		flex-wrap: wrap !important;
	}
	.flex-md-nowrap {
		flex-wrap: nowrap !important;
	}
	.flex-md-wrap-reverse {
		flex-wrap: wrap-reverse !important;
	}
	.justify-content-md-start {
		justify-content: flex-start !important;
	}
	.justify-content-md-end {
		justify-content: flex-end !important;
	}
	.justify-content-md-center {
		justify-content: center !important;
	}
	.justify-content-md-between {
		justify-content: space-between !important;
	}
	.justify-content-md-around {
		justify-content: space-around !important;
	}
	.align-items-md-start {
		align-items: flex-start !important;
	}
	.align-items-md-end {
		align-items: flex-end !important;
	}
	.align-items-md-center {
		align-items: center !important;
	}
	.align-items-md-baseline {
		align-items: baseline !important;
	}
	.align-items-md-stretch {
		align-items: stretch !important;
	}
	.align-content-md-start {
		align-content: flex-start !important;
	}
	.align-content-md-end {
		align-content: flex-end !important;
	}
	.align-content-md-center {
		align-content: center !important;
	}
	.align-content-md-between {
		align-content: space-between !important;
	}
	.align-content-md-around {
		align-content: space-around !important;
	}
	.align-content-md-stretch {
		align-content: stretch !important;
	}
	.align-self-md-auto {
		align-self: auto !important;
	}
	.align-self-md-start {
		align-self: flex-start !important;
	}
	.align-self-md-end {
		align-self: flex-end !important;
	}
	.align-self-md-center {
		align-self: center !important;
	}
	.align-self-md-baseline {
		align-self: baseline !important;
	}
	.align-self-md-stretch {
		align-self: stretch !important;
	}
}

@media (min-width: 992px) {
	.flex-lg-first {
		order: -1;
	}
	.flex-lg-last {
		order: 1;
	}
	.flex-lg-unordered {
		order: 0;
	}
	.flex-lg-row {
		flex-direction: row !important;
	}
	.flex-lg-column {
		flex-direction: column !important;
	}
	.flex-lg-row-reverse {
		flex-direction: row-reverse !important;
	}
	.flex-lg-column-reverse {
		flex-direction: column-reverse !important;
	}
	.flex-lg-wrap {
		flex-wrap: wrap !important;
	}
	.flex-lg-nowrap {
		flex-wrap: nowrap !important;
	}
	.flex-lg-wrap-reverse {
		flex-wrap: wrap-reverse !important;
	}
	.justify-content-lg-start {
		justify-content: flex-start !important;
	}
	.justify-content-lg-end {
		justify-content: flex-end !important;
	}
	.justify-content-lg-center {
		justify-content: center !important;
	}
	.justify-content-lg-between {
		justify-content: space-between !important;
	}
	.justify-content-lg-around {
		justify-content: space-around !important;
	}
	.align-items-lg-start {
		align-items: flex-start !important;
	}
	.align-items-lg-end {
		align-items: flex-end !important;
	}
	.align-items-lg-center {
		align-items: center !important;
	}
	.align-items-lg-baseline {
		align-items: baseline !important;
	}
	.align-items-lg-stretch {
		align-items: stretch !important;
	}
	.align-content-lg-start {
		align-content: flex-start !important;
	}
	.align-content-lg-end {
		align-content: flex-end !important;
	}
	.align-content-lg-center {
		align-content: center !important;
	}
	.align-content-lg-between {
		align-content: space-between !important;
	}
	.align-content-lg-around {
		align-content: space-around !important;
	}
	.align-content-lg-stretch {
		align-content: stretch !important;
	}
	.align-self-lg-auto {
		align-self: auto !important;
	}
	.align-self-lg-start {
		align-self: flex-start !important;
	}
	.align-self-lg-end {
		align-self: flex-end !important;
	}
	.align-self-lg-center {
		align-self: center !important;
	}
	.align-self-lg-baseline {
		align-self: baseline !important;
	}
	.align-self-lg-stretch {
		align-self: stretch !important;
	}
}

@media (min-width: 1200px) {
	.flex-xl-first {
		order: -1;
	}
	.flex-xl-last {
		order: 1;
	}
	.flex-xl-unordered {
		order: 0;
	}
	.flex-xl-row {
		flex-direction: row !important;
	}
	.flex-xl-column {
		flex-direction: column !important;
	}
	.flex-xl-row-reverse {
		flex-direction: row-reverse !important;
	}
	.flex-xl-column-reverse {
		flex-direction: column-reverse !important;
	}
	.flex-xl-wrap {
		flex-wrap: wrap !important;
	}
	.flex-xl-nowrap {
		flex-wrap: nowrap !important;
	}
	.flex-xl-wrap-reverse {
		flex-wrap: wrap-reverse !important;
	}
	.justify-content-xl-start {
		justify-content: flex-start !important;
	}
	.justify-content-xl-end {
		justify-content: flex-end !important;
	}
	.justify-content-xl-center {
		justify-content: center !important;
	}
	.justify-content-xl-between {
		justify-content: space-between !important;
	}
	.justify-content-xl-around {
		justify-content: space-around !important;
	}
	.align-items-xl-start {
		align-items: flex-start !important;
	}
	.align-items-xl-end {
		align-items: flex-end !important;
	}
	.align-items-xl-center {
		align-items: center !important;
	}
	.align-items-xl-baseline {
		align-items: baseline !important;
	}
	.align-items-xl-stretch {
		align-items: stretch !important;
	}
	.align-content-xl-start {
		align-content: flex-start !important;
	}
	.align-content-xl-end {
		align-content: flex-end !important;
	}
	.align-content-xl-center {
		align-content: center !important;
	}
	.align-content-xl-between {
		align-content: space-between !important;
	}
	.align-content-xl-around {
		align-content: space-around !important;
	}
	.align-content-xl-stretch {
		align-content: stretch !important;
	}
	.align-self-xl-auto {
		align-self: auto !important;
	}
	.align-self-xl-start {
		align-self: flex-start !important;
	}
	.align-self-xl-end {
		align-self: flex-end !important;
	}
	.align-self-xl-center {
		align-self: center !important;
	}
	.align-self-xl-baseline {
		align-self: baseline !important;
	}
	.align-self-xl-stretch {
		align-self: stretch !important;
	}
}

.float-left {
	float: left !important;
}

.float-right {
	float: right !important;
}

.float-none {
	float: none !important;
}

@media (min-width: 576px) {
	.float-sm-left {
		float: left !important;
	}
	.float-sm-right {
		float: right !important;
	}
	.float-sm-none {
		float: none !important;
	}
}

@media (min-width: 768px) {
	.float-md-left {
		float: left !important;
	}
	.float-md-right {
		float: right !important;
	}
	.float-md-none {
		float: none !important;
	}
}

@media (min-width: 992px) {
	.float-lg-left {
		float: left !important;
	}
	.float-lg-right {
		float: right !important;
	}
	.float-lg-none {
		float: none !important;
	}
}

@media (min-width: 1200px) {
	.float-xl-left {
		float: left !important;
	}
	.float-xl-right {
		float: right !important;
	}
	.float-xl-none {
		float: none !important;
	}
}

.fixed-top {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	z-index: 1030;
}

.fixed-bottom {
	position: fixed;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1030;
}

.sticky-top {
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	z-index: 1030;
}

.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
	position: static;
	width: auto;
	height: auto;
	margin: 0;
	overflow: visible;
	clip: auto;
}


.m-0 {
	margin: 0 0 !important;
}

.mt-0 {
	margin-top: 0 !important;
}

.mr-0 {
	margin-right: 0 !important;
}

.mb-0 {
	margin-bottom: 0 !important;
}

.ml-0 {
	margin-left: 0 !important;
}

.mx-0 {
	margin-right: 0 !important;
	margin-left: 0 !important;
}

.my-0 {
	margin-top: 0 !important;
	margin-bottom: 0 !important;
}

.m-1 {
	margin: 0.25rem 0.25rem !important;
}

.mt-1 {
	margin-top: 0.25rem !important;
}

.mr-1 {
	margin-right: 0.25rem !important;
}

.mb-1 {
	margin-bottom: 0.25rem !important;
}

.ml-1 {
	margin-left: 0.25rem !important;
}

.mx-1 {
	margin-right: 0.25rem !important;
	margin-left: 0.25rem !important;
}

.my-1 {
	margin-top: 0.25rem !important;
	margin-bottom: 0.25rem !important;
}

.m-2 {
	margin: 0.5rem 0.5rem !important;
}

.mt-2 {
	margin-top: 0.5rem !important;
}

.mr-2 {
	margin-right: 0.5rem !important;
}

.mb-2 {
	margin-bottom: 0.5rem !important;
}

.ml-2 {
	margin-left: 0.5rem !important;
}

.mx-2 {
	margin-right: 0.5rem !important;
	margin-left: 0.5rem !important;
}

.my-2 {
	margin-top: 0.5rem !important;
	margin-bottom: 0.5rem !important;
}

.m-3 {
	margin: 1rem 1rem !important;
}

.mt-3 {
	margin-top: 1rem !important;
}

.mr-3 {
	margin-right: 1rem !important;
}

.mb-3 {
	margin-bottom: 1rem !important;
}

.ml-3 {
	margin-left: 1rem !important;
}

.mx-3 {
	margin-right: 1rem !important;
	margin-left: 1rem !important;
}

.my-3 {
	margin-top: 1rem !important;
	margin-bottom: 1rem !important;
}

.m-4 {
	margin: 1.5rem 1.5rem !important;
}

.mt-4 {
	margin-top: 1.5rem !important;
}

.mr-4 {
	margin-right: 1.5rem !important;
}

.mb-4 {
	margin-bottom: 1.5rem !important;
}

.ml-4 {
	margin-left: 1.5rem !important;
}

.mx-4 {
	margin-right: 1.5rem !important;
	margin-left: 1.5rem !important;
}

.my-4 {
	margin-top: 1.5rem !important;
	margin-bottom: 1.5rem !important;
}

.m-5 {
	margin: 3rem 3rem !important;
}

.mt-5 {
	margin-top: 3rem !important;
}

.mr-5 {
	margin-right: 3rem !important;
}

.mb-5 {
	margin-bottom: 3rem !important;
}

.ml-5 {
	margin-left: 3rem !important;
}

.mx-5 {
	margin-right: 3rem !important;
	margin-left: 3rem !important;
}

.my-5 {
	margin-top: 3rem !important;
	margin-bottom: 3rem !important;
}

.p-0 {
	padding: 0 0 !important;
}

.pt-0 {
	padding-top: 0 !important;
}

.pr-0 {
	padding-right: 0 !important;
}

.pb-0 {
	padding-bottom: 0 !important;
}

.pl-0 {
	padding-left: 0 !important;
}

.px-0 {
	padding-right: 0 !important;
	padding-left: 0 !important;
}

.py-0 {
	padding-top: 0 !important;
	padding-bottom: 0 !important;
}

.p-1 {
	padding: 0.25rem 0.25rem !important;
}

.pt-1 {
	padding-top: 0.25rem !important;
}

.pr-1 {
	padding-right: 0.25rem !important;
}

.pb-1 {
	padding-bottom: 0.25rem !important;
}

.pl-1 {
	padding-left: 0.25rem !important;
}

.px-1 {
	padding-right: 0.25rem !important;
	padding-left: 0.25rem !important;
}

.py-1 {
	padding-top: 0.25rem !important;
	padding-bottom: 0.25rem !important;
}

.p-2 {
	padding: 0.5rem 0.5rem !important;
}

.pt-2 {
	padding-top: 0.5rem !important;
}

.pr-2 {
	padding-right: 0.5rem !important;
}

.pb-2 {
	padding-bottom: 0.5rem !important;
}

.pl-2 {
	padding-left: 0.5rem !important;
}

.px-2 {
	padding-right: 0.5rem !important;
	padding-left: 0.5rem !important;
}

.py-2 {
	padding-top: 0.5rem !important;
	padding-bottom: 0.5rem !important;
}

.p-3 {
	padding: 1rem 1rem !important;
}

.pt-3 {
	padding-top: 1rem !important;
}

.pr-3 {
	padding-right: 1rem !important;
}

.pb-3 {
	padding-bottom: 1rem !important;
}

.pl-3 {
	padding-left: 1rem !important;
}

.px-3 {
	padding-right: 1rem !important;
	padding-left: 1rem !important;
}

.py-3 {
	padding-top: 1rem !important;
	padding-bottom: 1rem !important;
}

.p-4 {
	padding: 1.5rem 1.5rem !important;
}

.pt-4 {
	padding-top: 1.5rem !important;
}

.pr-4 {
	padding-right: 1.5rem !important;
}

.pb-4 {
	padding-bottom: 1.5rem !important;
}

.pl-4 {
	padding-left: 1.5rem !important;
}

.px-4 {
	padding-right: 1.5rem !important;
	padding-left: 1.5rem !important;
}

.py-4 {
	padding-top: 1.5rem !important;
	padding-bottom: 1.5rem !important;
}

.p-5 {
	padding: 3rem 3rem !important;
}

.pt-5 {
	padding-top: 3rem !important;
}

.pr-5 {
	padding-right: 3rem !important;
}

.pb-5 {
	padding-bottom: 3rem !important;
}

.pl-5 {
	padding-left: 3rem !important;
}

.px-5 {
	padding-right: 3rem !important;
	padding-left: 3rem !important;
}

.py-5 {
	padding-top: 3rem !important;
	padding-bottom: 3rem !important;
}

.m-auto {
	margin: auto !important;
}

.mt-auto {
	margin-top: auto !important;
}

.mr-auto {
	margin-right: auto !important;
}

.mb-auto {
	margin-bottom: auto !important;
}

.ml-auto {
	margin-left: auto !important;
}

.mx-auto {
	margin-right: auto !important;
	margin-left: auto !important;
}

.my-auto {
	margin-top: auto !important;
	margin-bottom: auto !important;
}

@media (min-width: 576px) {
	.m-sm-0 {
		margin: 0 0 !important;
	}
	.mt-sm-0 {
		margin-top: 0 !important;
	}
	.mr-sm-0 {
		margin-right: 0 !important;
	}
	.mb-sm-0 {
		margin-bottom: 0 !important;
	}
	.ml-sm-0 {
		margin-left: 0 !important;
	}
	.mx-sm-0 {
		margin-right: 0 !important;
		margin-left: 0 !important;
	}
	.my-sm-0 {
		margin-top: 0 !important;
		margin-bottom: 0 !important;
	}
	.m-sm-1 {
		margin: 0.25rem 0.25rem !important;
	}
	.mt-sm-1 {
		margin-top: 0.25rem !important;
	}
	.mr-sm-1 {
		margin-right: 0.25rem !important;
	}
	.mb-sm-1 {
		margin-bottom: 0.25rem !important;
	}
	.ml-sm-1 {
		margin-left: 0.25rem !important;
	}
	.mx-sm-1 {
		margin-right: 0.25rem !important;
		margin-left: 0.25rem !important;
	}
	.my-sm-1 {
		margin-top: 0.25rem !important;
		margin-bottom: 0.25rem !important;
	}
	.m-sm-2 {
		margin: 0.5rem 0.5rem !important;
	}
	.mt-sm-2 {
		margin-top: 0.5rem !important;
	}
	.mr-sm-2 {
		margin-right: 0.5rem !important;
	}
	.mb-sm-2 {
		margin-bottom: 0.5rem !important;
	}
	.ml-sm-2 {
		margin-left: 0.5rem !important;
	}
	.mx-sm-2 {
		margin-right: 0.5rem !important;
		margin-left: 0.5rem !important;
	}
	.my-sm-2 {
		margin-top: 0.5rem !important;
		margin-bottom: 0.5rem !important;
	}
	.m-sm-3 {
		margin: 1rem 1rem !important;
	}
	.mt-sm-3 {
		margin-top: 1rem !important;
	}
	.mr-sm-3 {
		margin-right: 1rem !important;
	}
	.mb-sm-3 {
		margin-bottom: 1rem !important;
	}
	.ml-sm-3 {
		margin-left: 1rem !important;
	}
	.mx-sm-3 {
		margin-right: 1rem !important;
		margin-left: 1rem !important;
	}
	.my-sm-3 {
		margin-top: 1rem !important;
		margin-bottom: 1rem !important;
	}
	.m-sm-4 {
		margin: 1.5rem 1.5rem !important;
	}
	.mt-sm-4 {
		margin-top: 1.5rem !important;
	}
	.mr-sm-4 {
		margin-right: 1.5rem !important;
	}
	.mb-sm-4 {
		margin-bottom: 1.5rem !important;
	}
	.ml-sm-4 {
		margin-left: 1.5rem !important;
	}
	.mx-sm-4 {
		margin-right: 1.5rem !important;
		margin-left: 1.5rem !important;
	}
	.my-sm-4 {
		margin-top: 1.5rem !important;
		margin-bottom: 1.5rem !important;
	}
	.m-sm-5 {
		margin: 3rem 3rem !important;
	}
	.mt-sm-5 {
		margin-top: 3rem !important;
	}
	.mr-sm-5 {
		margin-right: 3rem !important;
	}
	.mb-sm-5 {
		margin-bottom: 3rem !important;
	}
	.ml-sm-5 {
		margin-left: 3rem !important;
	}
	.mx-sm-5 {
		margin-right: 3rem !important;
		margin-left: 3rem !important;
	}
	.my-sm-5 {
		margin-top: 3rem !important;
		margin-bottom: 3rem !important;
	}
	.p-sm-0 {
		padding: 0 0 !important;
	}
	.pt-sm-0 {
		padding-top: 0 !important;
	}
	.pr-sm-0 {
		padding-right: 0 !important;
	}
	.pb-sm-0 {
		padding-bottom: 0 !important;
	}
	.pl-sm-0 {
		padding-left: 0 !important;
	}
	.px-sm-0 {
		padding-right: 0 !important;
		padding-left: 0 !important;
	}
	.py-sm-0 {
		padding-top: 0 !important;
		padding-bottom: 0 !important;
	}
	.p-sm-1 {
		padding: 0.25rem 0.25rem !important;
	}
	.pt-sm-1 {
		padding-top: 0.25rem !important;
	}
	.pr-sm-1 {
		padding-right: 0.25rem !important;
	}
	.pb-sm-1 {
		padding-bottom: 0.25rem !important;
	}
	.pl-sm-1 {
		padding-left: 0.25rem !important;
	}
	.px-sm-1 {
		padding-right: 0.25rem !important;
		padding-left: 0.25rem !important;
	}
	.py-sm-1 {
		padding-top: 0.25rem !important;
		padding-bottom: 0.25rem !important;
	}
	.p-sm-2 {
		padding: 0.5rem 0.5rem !important;
	}
	.pt-sm-2 {
		padding-top: 0.5rem !important;
	}
	.pr-sm-2 {
		padding-right: 0.5rem !important;
	}
	.pb-sm-2 {
		padding-bottom: 0.5rem !important;
	}
	.pl-sm-2 {
		padding-left: 0.5rem !important;
	}
	.px-sm-2 {
		padding-right: 0.5rem !important;
		padding-left: 0.5rem !important;
	}
	.py-sm-2 {
		padding-top: 0.5rem !important;
		padding-bottom: 0.5rem !important;
	}
	.p-sm-3 {
		padding: 1rem 1rem !important;
	}
	.pt-sm-3 {
		padding-top: 1rem !important;
	}
	.pr-sm-3 {
		padding-right: 1rem !important;
	}
	.pb-sm-3 {
		padding-bottom: 1rem !important;
	}
	.pl-sm-3 {
		padding-left: 1rem !important;
	}
	.px-sm-3 {
		padding-right: 1rem !important;
		padding-left: 1rem !important;
	}
	.py-sm-3 {
		padding-top: 1rem !important;
		padding-bottom: 1rem !important;
	}
	.p-sm-4 {
		padding: 1.5rem 1.5rem !important;
	}
	.pt-sm-4 {
		padding-top: 1.5rem !important;
	}
	.pr-sm-4 {
		padding-right: 1.5rem !important;
	}
	.pb-sm-4 {
		padding-bottom: 1.5rem !important;
	}
	.pl-sm-4 {
		padding-left: 1.5rem !important;
	}
	.px-sm-4 {
		padding-right: 1.5rem !important;
		padding-left: 1.5rem !important;
	}
	.py-sm-4 {
		padding-top: 1.5rem !important;
		padding-bottom: 1.5rem !important;
	}
	.p-sm-5 {
		padding: 3rem 3rem !important;
	}
	.pt-sm-5 {
		padding-top: 3rem !important;
	}
	.pr-sm-5 {
		padding-right: 3rem !important;
	}
	.pb-sm-5 {
		padding-bottom: 3rem !important;
	}
	.pl-sm-5 {
		padding-left: 3rem !important;
	}
	.px-sm-5 {
		padding-right: 3rem !important;
		padding-left: 3rem !important;
	}
	.py-sm-5 {
		padding-top: 3rem !important;
		padding-bottom: 3rem !important;
	}
	.m-sm-auto {
		margin: auto !important;
	}
	.mt-sm-auto {
		margin-top: auto !important;
	}
	.mr-sm-auto {
		margin-right: auto !important;
	}
	.mb-sm-auto {
		margin-bottom: auto !important;
	}
	.ml-sm-auto {
		margin-left: auto !important;
	}
	.mx-sm-auto {
		margin-right: auto !important;
		margin-left: auto !important;
	}
	.my-sm-auto {
		margin-top: auto !important;
		margin-bottom: auto !important;
	}
}

@media (min-width: 768px) {
	.m-md-0 {
		margin: 0 0 !important;
	}
	.mt-md-0 {
		margin-top: 0 !important;
	}
	.mr-md-0 {
		margin-right: 0 !important;
	}
	.mb-md-0 {
		margin-bottom: 0 !important;
	}
	.ml-md-0 {
		margin-left: 0 !important;
	}
	.mx-md-0 {
		margin-right: 0 !important;
		margin-left: 0 !important;
	}
	.my-md-0 {
		margin-top: 0 !important;
		margin-bottom: 0 !important;
	}
	.m-md-1 {
		margin: 0.25rem 0.25rem !important;
	}
	.mt-md-1 {
		margin-top: 0.25rem !important;
	}
	.mr-md-1 {
		margin-right: 0.25rem !important;
	}
	.mb-md-1 {
		margin-bottom: 0.25rem !important;
	}
	.ml-md-1 {
		margin-left: 0.25rem !important;
	}
	.mx-md-1 {
		margin-right: 0.25rem !important;
		margin-left: 0.25rem !important;
	}
	.my-md-1 {
		margin-top: 0.25rem !important;
		margin-bottom: 0.25rem !important;
	}
	.m-md-2 {
		margin: 0.5rem 0.5rem !important;
	}
	.mt-md-2 {
		margin-top: 0.5rem !important;
	}
	.mr-md-2 {
		margin-right: 0.5rem !important;
	}
	.mb-md-2 {
		margin-bottom: 0.5rem !important;
	}
	.ml-md-2 {
		margin-left: 0.5rem !important;
	}
	.mx-md-2 {
		margin-right: 0.5rem !important;
		margin-left: 0.5rem !important;
	}
	.my-md-2 {
		margin-top: 0.5rem !important;
		margin-bottom: 0.5rem !important;
	}
	.m-md-3 {
		margin: 1rem 1rem !important;
	}
	.mt-md-3 {
		margin-top: 1rem !important;
	}
	.mr-md-3 {
		margin-right: 1rem !important;
	}
	.mb-md-3 {
		margin-bottom: 1rem !important;
	}
	.ml-md-3 {
		margin-left: 1rem !important;
	}
	.mx-md-3 {
		margin-right: 1rem !important;
		margin-left: 1rem !important;
	}
	.my-md-3 {
		margin-top: 1rem !important;
		margin-bottom: 1rem !important;
	}
	.m-md-4 {
		margin: 1.5rem 1.5rem !important;
	}
	.mt-md-4 {
		margin-top: 1.5rem !important;
	}
	.mr-md-4 {
		margin-right: 1.5rem !important;
	}
	.mb-md-4 {
		margin-bottom: 1.5rem !important;
	}
	.ml-md-4 {
		margin-left: 1.5rem !important;
	}
	.mx-md-4 {
		margin-right: 1.5rem !important;
		margin-left: 1.5rem !important;
	}
	.my-md-4 {
		margin-top: 1.5rem !important;
		margin-bottom: 1.5rem !important;
	}
	.m-md-5 {
		margin: 3rem 3rem !important;
	}
	.mt-md-5 {
		margin-top: 3rem !important;
	}
	.mr-md-5 {
		margin-right: 3rem !important;
	}
	.mb-md-5 {
		margin-bottom: 3rem !important;
	}
	.ml-md-5 {
		margin-left: 3rem !important;
	}
	.mx-md-5 {
		margin-right: 3rem !important;
		margin-left: 3rem !important;
	}
	.my-md-5 {
		margin-top: 3rem !important;
		margin-bottom: 3rem !important;
	}
	.p-md-0 {
		padding: 0 0 !important;
	}
	.pt-md-0 {
		padding-top: 0 !important;
	}
	.pr-md-0 {
		padding-right: 0 !important;
	}
	.pb-md-0 {
		padding-bottom: 0 !important;
	}
	.pl-md-0 {
		padding-left: 0 !important;
	}
	.px-md-0 {
		padding-right: 0 !important;
		padding-left: 0 !important;
	}
	.py-md-0 {
		padding-top: 0 !important;
		padding-bottom: 0 !important;
	}
	.p-md-1 {
		padding: 0.25rem 0.25rem !important;
	}
	.pt-md-1 {
		padding-top: 0.25rem !important;
	}
	.pr-md-1 {
		padding-right: 0.25rem !important;
	}
	.pb-md-1 {
		padding-bottom: 0.25rem !important;
	}
	.pl-md-1 {
		padding-left: 0.25rem !important;
	}
	.px-md-1 {
		padding-right: 0.25rem !important;
		padding-left: 0.25rem !important;
	}
	.py-md-1 {
		padding-top: 0.25rem !important;
		padding-bottom: 0.25rem !important;
	}
	.p-md-2 {
		padding: 0.5rem 0.5rem !important;
	}
	.pt-md-2 {
		padding-top: 0.5rem !important;
	}
	.pr-md-2 {
		padding-right: 0.5rem !important;
	}
	.pb-md-2 {
		padding-bottom: 0.5rem !important;
	}
	.pl-md-2 {
		padding-left: 0.5rem !important;
	}
	.px-md-2 {
		padding-right: 0.5rem !important;
		padding-left: 0.5rem !important;
	}
	.py-md-2 {
		padding-top: 0.5rem !important;
		padding-bottom: 0.5rem !important;
	}
	.p-md-3 {
		padding: 1rem 1rem !important;
	}
	.pt-md-3 {
		padding-top: 1rem !important;
	}
	.pr-md-3 {
		padding-right: 1rem !important;
	}
	.pb-md-3 {
		padding-bottom: 1rem !important;
	}
	.pl-md-3 {
		padding-left: 1rem !important;
	}
	.px-md-3 {
		padding-right: 1rem !important;
		padding-left: 1rem !important;
	}
	.py-md-3 {
		padding-top: 1rem !important;
		padding-bottom: 1rem !important;
	}
	.p-md-4 {
		padding: 1.5rem 1.5rem !important;
	}
	.pt-md-4 {
		padding-top: 1.5rem !important;
	}
	.pr-md-4 {
		padding-right: 1.5rem !important;
	}
	.pb-md-4 {
		padding-bottom: 1.5rem !important;
	}
	.pl-md-4 {
		padding-left: 1.5rem !important;
	}
	.px-md-4 {
		padding-right: 1.5rem !important;
		padding-left: 1.5rem !important;
	}
	.py-md-4 {
		padding-top: 1.5rem !important;
		padding-bottom: 1.5rem !important;
	}
	.p-md-5 {
		padding: 3rem 3rem !important;
	}
	.pt-md-5 {
		padding-top: 3rem !important;
	}
	.pr-md-5 {
		padding-right: 3rem !important;
	}
	.pb-md-5 {
		padding-bottom: 3rem !important;
	}
	.pl-md-5 {
		padding-left: 3rem !important;
	}
	.px-md-5 {
		padding-right: 3rem !important;
		padding-left: 3rem !important;
	}
	.py-md-5 {
		padding-top: 3rem !important;
		padding-bottom: 3rem !important;
	}
	.m-md-auto {
		margin: auto !important;
	}
	.mt-md-auto {
		margin-top: auto !important;
	}
	.mr-md-auto {
		margin-right: auto !important;
	}
	.mb-md-auto {
		margin-bottom: auto !important;
	}
	.ml-md-auto {
		margin-left: auto !important;
	}
	.mx-md-auto {
		margin-right: auto !important;
		margin-left: auto !important;
	}
	.my-md-auto {
		margin-top: auto !important;
		margin-bottom: auto !important;
	}
}

@media (min-width: 992px) {
	.m-lg-0 {
		margin: 0 0 !important;
	}
	.mt-lg-0 {
		margin-top: 0 !important;
	}
	.mr-lg-0 {
		margin-right: 0 !important;
	}
	.mb-lg-0 {
		margin-bottom: 0 !important;
	}
	.ml-lg-0 {
		margin-left: 0 !important;
	}
	.mx-lg-0 {
		margin-right: 0 !important;
		margin-left: 0 !important;
	}
	.my-lg-0 {
		margin-top: 0 !important;
		margin-bottom: 0 !important;
	}
	.m-lg-1 {
		margin: 0.25rem 0.25rem !important;
	}
	.mt-lg-1 {
		margin-top: 0.25rem !important;
	}
	.mr-lg-1 {
		margin-right: 0.25rem !important;
	}
	.mb-lg-1 {
		margin-bottom: 0.25rem !important;
	}
	.ml-lg-1 {
		margin-left: 0.25rem !important;
	}
	.mx-lg-1 {
		margin-right: 0.25rem !important;
		margin-left: 0.25rem !important;
	}
	.my-lg-1 {
		margin-top: 0.25rem !important;
		margin-bottom: 0.25rem !important;
	}
	.m-lg-2 {
		margin: 0.5rem 0.5rem !important;
	}
	.mt-lg-2 {
		margin-top: 0.5rem !important;
	}
	.mr-lg-2 {
		margin-right: 0.5rem !important;
	}
	.mb-lg-2 {
		margin-bottom: 0.5rem !important;
	}
	.ml-lg-2 {
		margin-left: 0.5rem !important;
	}
	.mx-lg-2 {
		margin-right: 0.5rem !important;
		margin-left: 0.5rem !important;
	}
	.my-lg-2 {
		margin-top: 0.5rem !important;
		margin-bottom: 0.5rem !important;
	}
	.m-lg-3 {
		margin: 1rem 1rem !important;
	}
	.mt-lg-3 {
		margin-top: 1rem !important;
	}
	.mr-lg-3 {
		margin-right: 1rem !important;
	}
	.mb-lg-3 {
		margin-bottom: 1rem !important;
	}
	.ml-lg-3 {
		margin-left: 1rem !important;
	}
	.mx-lg-3 {
		margin-right: 1rem !important;
		margin-left: 1rem !important;
	}
	.my-lg-3 {
		margin-top: 1rem !important;
		margin-bottom: 1rem !important;
	}
	.m-lg-4 {
		margin: 1.5rem 1.5rem !important;
	}
	.mt-lg-4 {
		margin-top: 1.5rem !important;
	}
	.mr-lg-4 {
		margin-right: 1.5rem !important;
	}
	.mb-lg-4 {
		margin-bottom: 1.5rem !important;
	}
	.ml-lg-4 {
		margin-left: 1.5rem !important;
	}
	.mx-lg-4 {
		margin-right: 1.5rem !important;
		margin-left: 1.5rem !important;
	}
	.my-lg-4 {
		margin-top: 1.5rem !important;
		margin-bottom: 1.5rem !important;
	}
	.m-lg-5 {
		margin: 3rem 3rem !important;
	}
	.mt-lg-5 {
		margin-top: 3rem !important;
	}
	.mr-lg-5 {
		margin-right: 3rem !important;
	}
	.mb-lg-5 {
		margin-bottom: 3rem !important;
	}
	.ml-lg-5 {
		margin-left: 3rem !important;
	}
	.mx-lg-5 {
		margin-right: 3rem !important;
		margin-left: 3rem !important;
	}
	.my-lg-5 {
		margin-top: 3rem !important;
		margin-bottom: 3rem !important;
	}
	.p-lg-0 {
		padding: 0 0 !important;
	}
	.pt-lg-0 {
		padding-top: 0 !important;
	}
	.pr-lg-0 {
		padding-right: 0 !important;
	}
	.pb-lg-0 {
		padding-bottom: 0 !important;
	}
	.pl-lg-0 {
		padding-left: 0 !important;
	}
	.px-lg-0 {
		padding-right: 0 !important;
		padding-left: 0 !important;
	}
	.py-lg-0 {
		padding-top: 0 !important;
		padding-bottom: 0 !important;
	}
	.p-lg-1 {
		padding: 0.25rem 0.25rem !important;
	}
	.pt-lg-1 {
		padding-top: 0.25rem !important;
	}
	.pr-lg-1 {
		padding-right: 0.25rem !important;
	}
	.pb-lg-1 {
		padding-bottom: 0.25rem !important;
	}
	.pl-lg-1 {
		padding-left: 0.25rem !important;
	}
	.px-lg-1 {
		padding-right: 0.25rem !important;
		padding-left: 0.25rem !important;
	}
	.py-lg-1 {
		padding-top: 0.25rem !important;
		padding-bottom: 0.25rem !important;
	}
	.p-lg-2 {
		padding: 0.5rem 0.5rem !important;
	}
	.pt-lg-2 {
		padding-top: 0.5rem !important;
	}
	.pr-lg-2 {
		padding-right: 0.5rem !important;
	}
	.pb-lg-2 {
		padding-bottom: 0.5rem !important;
	}
	.pl-lg-2 {
		padding-left: 0.5rem !important;
	}
	.px-lg-2 {
		padding-right: 0.5rem !important;
		padding-left: 0.5rem !important;
	}
	.py-lg-2 {
		padding-top: 0.5rem !important;
		padding-bottom: 0.5rem !important;
	}
	.p-lg-3 {
		padding: 1rem 1rem !important;
	}
	.pt-lg-3 {
		padding-top: 1rem !important;
	}
	.pr-lg-3 {
		padding-right: 1rem !important;
	}
	.pb-lg-3 {
		padding-bottom: 1rem !important;
	}
	.pl-lg-3 {
		padding-left: 1rem !important;
	}
	.px-lg-3 {
		padding-right: 1rem !important;
		padding-left: 1rem !important;
	}
	.py-lg-3 {
		padding-top: 1rem !important;
		padding-bottom: 1rem !important;
	}
	.p-lg-4 {
		padding: 1.5rem 1.5rem !important;
	}
	.pt-lg-4 {
		padding-top: 1.5rem !important;
	}
	.pr-lg-4 {
		padding-right: 1.5rem !important;
	}
	.pb-lg-4 {
		padding-bottom: 1.5rem !important;
	}
	.pl-lg-4 {
		padding-left: 1.5rem !important;
	}
	.px-lg-4 {
		padding-right: 1.5rem !important;
		padding-left: 1.5rem !important;
	}
	.py-lg-4 {
		padding-top: 1.5rem !important;
		padding-bottom: 1.5rem !important;
	}
	.p-lg-5 {
		padding: 3rem 3rem !important;
	}
	.pt-lg-5 {
		padding-top: 3rem !important;
	}
	.pr-lg-5 {
		padding-right: 3rem !important;
	}
	.pb-lg-5 {
		padding-bottom: 3rem !important;
	}
	.pl-lg-5 {
		padding-left: 3rem !important;
	}
	.px-lg-5 {
		padding-right: 3rem !important;
		padding-left: 3rem !important;
	}
	.py-lg-5 {
		padding-top: 3rem !important;
		padding-bottom: 3rem !important;
	}
	.m-lg-auto {
		margin: auto !important;
	}
	.mt-lg-auto {
		margin-top: auto !important;
	}
	.mr-lg-auto {
		margin-right: auto !important;
	}
	.mb-lg-auto {
		margin-bottom: auto !important;
	}
	.ml-lg-auto {
		margin-left: auto !important;
	}
	.mx-lg-auto {
		margin-right: auto !important;
		margin-left: auto !important;
	}
	.my-lg-auto {
		margin-top: auto !important;
		margin-bottom: auto !important;
	}
}

@media (min-width: 1200px) {
	.m-xl-0 {
		margin: 0 0 !important;
	}
	.mt-xl-0 {
		margin-top: 0 !important;
	}
	.mr-xl-0 {
		margin-right: 0 !important;
	}
	.mb-xl-0 {
		margin-bottom: 0 !important;
	}
	.ml-xl-0 {
		margin-left: 0 !important;
	}
	.mx-xl-0 {
		margin-right: 0 !important;
		margin-left: 0 !important;
	}
	.my-xl-0 {
		margin-top: 0 !important;
		margin-bottom: 0 !important;
	}
	.m-xl-1 {
		margin: 0.25rem 0.25rem !important;
	}
	.mt-xl-1 {
		margin-top: 0.25rem !important;
	}
	.mr-xl-1 {
		margin-right: 0.25rem !important;
	}
	.mb-xl-1 {
		margin-bottom: 0.25rem !important;
	}
	.ml-xl-1 {
		margin-left: 0.25rem !important;
	}
	.mx-xl-1 {
		margin-right: 0.25rem !important;
		margin-left: 0.25rem !important;
	}
	.my-xl-1 {
		margin-top: 0.25rem !important;
		margin-bottom: 0.25rem !important;
	}
	.m-xl-2 {
		margin: 0.5rem 0.5rem !important;
	}
	.mt-xl-2 {
		margin-top: 0.5rem !important;
	}
	.mr-xl-2 {
		margin-right: 0.5rem !important;
	}
	.mb-xl-2 {
		margin-bottom: 0.5rem !important;
	}
	.ml-xl-2 {
		margin-left: 0.5rem !important;
	}
	.mx-xl-2 {
		margin-right: 0.5rem !important;
		margin-left: 0.5rem !important;
	}
	.my-xl-2 {
		margin-top: 0.5rem !important;
		margin-bottom: 0.5rem !important;
	}
	.m-xl-3 {
		margin: 1rem 1rem !important;
	}
	.mt-xl-3 {
		margin-top: 1rem !important;
	}
	.mr-xl-3 {
		margin-right: 1rem !important;
	}
	.mb-xl-3 {
		margin-bottom: 1rem !important;
	}
	.ml-xl-3 {
		margin-left: 1rem !important;
	}
	.mx-xl-3 {
		margin-right: 1rem !important;
		margin-left: 1rem !important;
	}
	.my-xl-3 {
		margin-top: 1rem !important;
		margin-bottom: 1rem !important;
	}
	.m-xl-4 {
		margin: 1.5rem 1.5rem !important;
	}
	.mt-xl-4 {
		margin-top: 1.5rem !important;
	}
	.mr-xl-4 {
		margin-right: 1.5rem !important;
	}
	.mb-xl-4 {
		margin-bottom: 1.5rem !important;
	}
	.ml-xl-4 {
		margin-left: 1.5rem !important;
	}
	.mx-xl-4 {
		margin-right: 1.5rem !important;
		margin-left: 1.5rem !important;
	}
	.my-xl-4 {
		margin-top: 1.5rem !important;
		margin-bottom: 1.5rem !important;
	}
	.m-xl-5 {
		margin: 3rem 3rem !important;
	}
	.mt-xl-5 {
		margin-top: 3rem !important;
	}
	.mr-xl-5 {
		margin-right: 3rem !important;
	}
	.mb-xl-5 {
		margin-bottom: 3rem !important;
	}
	.ml-xl-5 {
		margin-left: 3rem !important;
	}
	.mx-xl-5 {
		margin-right: 3rem !important;
		margin-left: 3rem !important;
	}
	.my-xl-5 {
		margin-top: 3rem !important;
		margin-bottom: 3rem !important;
	}
	.p-xl-0 {
		padding: 0 0 !important;
	}
	.pt-xl-0 {
		padding-top: 0 !important;
	}
	.pr-xl-0 {
		padding-right: 0 !important;
	}
	.pb-xl-0 {
		padding-bottom: 0 !important;
	}
	.pl-xl-0 {
		padding-left: 0 !important;
	}
	.px-xl-0 {
		padding-right: 0 !important;
		padding-left: 0 !important;
	}
	.py-xl-0 {
		padding-top: 0 !important;
		padding-bottom: 0 !important;
	}
	.p-xl-1 {
		padding: 0.25rem 0.25rem !important;
	}
	.pt-xl-1 {
		padding-top: 0.25rem !important;
	}
	.pr-xl-1 {
		padding-right: 0.25rem !important;
	}
	.pb-xl-1 {
		padding-bottom: 0.25rem !important;
	}
	.pl-xl-1 {
		padding-left: 0.25rem !important;
	}
	.px-xl-1 {
		padding-right: 0.25rem !important;
		padding-left: 0.25rem !important;
	}
	.py-xl-1 {
		padding-top: 0.25rem !important;
		padding-bottom: 0.25rem !important;
	}
	.p-xl-2 {
		padding: 0.5rem 0.5rem !important;
	}
	.pt-xl-2 {
		padding-top: 0.5rem !important;
	}
	.pr-xl-2 {
		padding-right: 0.5rem !important;
	}
	.pb-xl-2 {
		padding-bottom: 0.5rem !important;
	}
	.pl-xl-2 {
		padding-left: 0.5rem !important;
	}
	.px-xl-2 {
		padding-right: 0.5rem !important;
		padding-left: 0.5rem !important;
	}
	.py-xl-2 {
		padding-top: 0.5rem !important;
		padding-bottom: 0.5rem !important;
	}
	.p-xl-3 {
		padding: 1rem 1rem !important;
	}
	.pt-xl-3 {
		padding-top: 1rem !important;
	}
	.pr-xl-3 {
		padding-right: 1rem !important;
	}
	.pb-xl-3 {
		padding-bottom: 1rem !important;
	}
	.pl-xl-3 {
		padding-left: 1rem !important;
	}
	.px-xl-3 {
		padding-right: 1rem !important;
		padding-left: 1rem !important;
	}
	.py-xl-3 {
		padding-top: 1rem !important;
		padding-bottom: 1rem !important;
	}
	.p-xl-4 {
		padding: 1.5rem 1.5rem !important;
	}
	.pt-xl-4 {
		padding-top: 1.5rem !important;
	}
	.pr-xl-4 {
		padding-right: 1.5rem !important;
	}
	.pb-xl-4 {
		padding-bottom: 1.5rem !important;
	}
	.pl-xl-4 {
		padding-left: 1.5rem !important;
	}
	.px-xl-4 {
		padding-right: 1.5rem !important;
		padding-left: 1.5rem !important;
	}
	.py-xl-4 {
		padding-top: 1.5rem !important;
		padding-bottom: 1.5rem !important;
	}
	.p-xl-5 {
		padding: 3rem 3rem !important;
	}
	.pt-xl-5 {
		padding-top: 3rem !important;
	}
	.pr-xl-5 {
		padding-right: 3rem !important;
	}
	.pb-xl-5 {
		padding-bottom: 3rem !important;
	}
	.pl-xl-5 {
		padding-left: 3rem !important;
	}
	.px-xl-5 {
		padding-right: 3rem !important;
		padding-left: 3rem !important;
	}
	.py-xl-5 {
		padding-top: 3rem !important;
		padding-bottom: 3rem !important;
	}
	.m-xl-auto {
		margin: auto !important;
	}
	.mt-xl-auto {
		margin-top: auto !important;
	}
	.mr-xl-auto {
		margin-right: auto !important;
	}
	.mb-xl-auto {
		margin-bottom: auto !important;
	}
	.ml-xl-auto {
		margin-left: auto !important;
	}
	.mx-xl-auto {
		margin-right: auto !important;
		margin-left: auto !important;
	}
	.my-xl-auto {
		margin-top: auto !important;
		margin-bottom: auto !important;
	}
}

.text-justify {
	text-align: justify !important;
}

.text-nowrap {
	white-space: nowrap !important;
}

.text-truncate {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.text-left {
	text-align: left !important;
}

.text-right {
	text-align: right !important;
}

.text-center {
	text-align: center !important;
}

@media (min-width: 576px) {
	.text-sm-left {
		text-align: left !important;
	}
	.text-sm-right {
		text-align: right !important;
	}
	.text-sm-center {
		text-align: center !important;
	}
}

@media (min-width: 768px) {
	.text-md-left {
		text-align: left !important;
	}
	.text-md-right {
		text-align: right !important;
	}
	.text-md-center {
		text-align: center !important;
	}
}

@media (min-width: 992px) {
	.text-lg-left {
		text-align: left !important;
	}
	.text-lg-right {
		text-align: right !important;
	}
	.text-lg-center {
		text-align: center !important;
	}
}

@media (min-width: 1200px) {
	.text-xl-left {
		text-align: left !important;
	}
	.text-xl-right {
		text-align: right !important;
	}
	.text-xl-center {
		text-align: center !important;
	}
}

.text-lowercase {
	text-transform: lowercase !important;
}

.text-uppercase {
	text-transform: uppercase !important;
}

.text-capitalize {
	text-transform: capitalize !important;
}

.font-weight-normal {
	font-weight: normal;
}

.font-weight-bold {
	font-weight: bold;
}

.font-italic {
	font-style: italic;
}

.text-white {
	color: #fff !important;
}

.text-muted {
	color: #636c72 !important;
}

a.text-muted:focus, a.text-muted:hover {
	color: #4b5257 !important;
}

.text-primary {
	color: #0275d8 !important;
}

a.text-primary:focus, a.text-primary:hover {
	color: #025aa5 !important;
}

.text-success {
	color: #5cb85c !important;
}

a.text-success:focus, a.text-success:hover {
	color: #449d44 !important;
}

.text-info {
	color: #5bc0de !important;
}

a.text-info:focus, a.text-info:hover {
	color: #31b0d5 !important;
}

.text-warning {
	color: #f0ad4e !important;
}

a.text-warning:focus, a.text-warning:hover {
	color: #ec971f !important;
}

.text-danger {
	color: #d9534f !important;
}

a.text-danger:focus, a.text-danger:hover {
	color: #c9302c !important;
}

.text-gray-dark {
	color: #292b2c !important;
}

a.text-gray-dark:focus, a.text-gray-dark:hover {
	color: #101112 !important;
}

.text-hide {
	font: 0/0 a;
	color: transparent;
	text-shadow: none;
	background-color: transparent;
	border: 0;
}

.text-underline {
	text-decoration: underline;
}
/* =======================================
	1. GENERAL
	-- 1.1 Heading
	-- 1.2 Text
  -- 1.3 Tab
  -- 1.4 Breadcrumb

	2. FORM
	-- 2.1 Button
	-- 2.2 Select
	-- 2.3 Picker
	-- 2.4 Input

	3. MODAL

	4. MAIN CONTENT
	-- 4.1 Subheader
  -- 4.2 Page Content

  5. PLUGINS
  -- 5.1 Datepicker
  -- 5.2 Tag Input
  -- 5.3 Switch
  -- 5.4 Timepicker
  -- 5.5 React-image-crop
  -- 5.6 Table
  -- 5.7 Date Range Picker
======================================= */

/*===== 1. GENERAL =====*/
html {
  word-break: break-word;
}
.hidden {
  display: none !important;
}
.clickable {
  cursor: pointer !important;
}
@media only screen and (max-width: 767px) {
  .isoLayoutContentWrapper > div,
  .isoLayoutContentWrapper > .isoLayoutContent {
    padding: 10px;
  }
}

.flex-1 {
  flex: 1 1;
}

.image-full {
  width: 100%;
  height: 100%;
}

.ant-modal-mask,
.ant-modal-wrap {
  z-index: 1001;
}

/*----- 1.2 Text -----*/
.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-success {
  color: #02c498;
}

.text-danger {
  color: #ef4c66;
}
.text-strikethrough {
  text-decoration: line-through;
}

.text-muted {
  font-size: 80%;
  margin-top: 5px;
  display: block;
}

.text-bold {
  font-weight: 900;
}

.lineHeight1 {
  line-height: 1;
}
/*----- 1.2 Text -----*/

/*----- 1.3 Tab -----*/
.ant-tabs-ink-bar {
  background-color: #fd9648;
}
/*----- END 1.3 Tab -----*/
/*----- 1.4 Breadcrumb -----*/
.breadcrumb-wrapper {
  background: #ffffff;
  width: 100%;
  padding: 8px 15px;
  margin-bottom: 15px;
}
.breadcrumb-wrapper .ant-breadcrumb-link a {
  color: #fd9648;
}
/*----- END 1.4 Breadcrumb -----*/

/*===== END 1. GENERAL =====*/

/*===== 2. FORM =====*/
.ant-form .ant-form-item {
  margin-bottom: 15px;
  padding-bottom: 0;
}
.ant-form .ant-form-item label {
  line-height: 1.2;
}
.ant-form .ant-form-item-label label {
  margin-bottom: 8px;
}
.ant-form .ant-form-item-label label,
.ant-radio-group .ant-radio-wrapper {
  color: #949494;
  text-transform: uppercase;
  font-size: 12px;
}
.ant-form .ant-form-item .DateInput {
  font-size: 14px;
  width: 180px;
}
.ant-form .ant-form-item .DateInput_input {
  padding: 2px 8px;
  font-size: 14px;
}
.ant-form .ant-form-item .DateInput .DateInput_input__focused {
  border-bottom: 2px solid #fd9648;
}
.ant-form textarea.ant-input {
  margin-bottom: 0;
}

.help-error {
  font-size: 12px;
  color: #f5222d;
}

/*----- 2.1 Button -----*/
/* Buttons */
button[type="button"].ant-btn {
  text-transform: uppercase;
  font-weight: 400;
}
button[type="button"].ant-btn.ant-btn-sm {
  height: 30px;
}
.btn-success {
  background-color: #52c41a !important;
  border-color: #52c41a !important;
  color: #ffffff !important;
}
/*----- END 2.1 Button -----*/

/*----- 2.2 Select -----*/
.ant-select {
  width: 100%;
}
.ant-select .ant-select-selection--single {
  height: 35px;
  border: 1px solid #e9e9e9;
}
.ant-select-dropdown-menu-item {
  line-height: 1.2;
}
.ant-select-dropdown-menu-item-group-title {
  height: auto;
  line-height: 1.2;
  padding: 5px 12px;
}
.ant-select-dropdown-menu-item-group {
  list-style-type: none;
}
.ant-select-dropdown-menu-item-group
  > .ant-select-dropdown-menu-item-group-title,
.ant-select-dropdown-menu-item-group
  > .ant-select-dropdown-menu-item-group-list {
  padding-left: 10px;
}
.ant-select-dropdown-menu-item-group-list > .ant-select-dropdown-menu-item {
  padding-left: 10px;
}
/*----- END 2.2 Select -----*/

/*----- 2.3 Picker -----*/
.ant-input-group .ant-calendar-picker-input {
  height: 35px;
  border: 1px solid #e9e9e9;
}
/*----- END 2.3 Picker -----*/

/*----- 2.4 Input -----*/
/*----- END 2.4 Input -----*/
/*===== END 2. FORM =====*/

/*===== 3. MODAL =====*/
.ant-modal-title {
  font-size: 20px;
  text-transform: uppercase;
  color: #707070;
}
.ant-modal .DateInput {
  font-size: 14px;
  width: 180px;
}
.ant-modal .DateInput_input {
  padding: 2px 8px;
  font-size: 14px;
}
.ant-modal .DateInput .DateInput_input__focused {
  border-bottom: 2px solid #fd9648;
}
/*===== END 3. MODAL =====*/

/*===== 5. PLUGINS =====*/
/*----- 5.1 Datepicker -----*/
/* Date Picker */
.DayPicker .CalendarDay__selected,
.DayPicker .CalendarDay__selected:active,
.DayPicker .CalendarDay__selected:hover {
  background: #fd9648;
  border: 1px double #fd9648;
}
.DayPicker .CalendarDay__selected_span {
  background: #fec193;
  border: 1px double #ffeddf;
}
.DayPicker .CalendarDay__selected_span:active,
.DayPicker .CalendarDay__selected_span:hover,
.DayPicker .CalendarDay__hovered_span,
.DayPicker .CalendarDay__hovered_span:hover {
  background: #ffeddf;
  border: 0;
  color: #fd9648;
  border: 1px double #ffeddf;
}
.DayPicker .CalendarDay__default:hover {
  background: #ffeddf;
  color: #fec193;
}
.DayPicker .DayPickerKeyboardShortcuts_show__bottomRight {
  display: none;
}
/*.isoStickerWidget {
  height: 150px;
}*/
.ant-form-item .ant-calendar-picker {
  width: 100% !important;
}
.ant-form-item .ant-calendar-picker .ant-calendar-picker-input {
  height: 35px;
  border: 1px solid #e9e9e9;
}
/*----- END 5.1 Datepicker -----*/
/*----- 5.2 Tag Input -----*/
.react-tags-wrapper input {
  padding: 4px 10px;
  width: 100%;
  height: 35px;
  cursor: text;
  text-align: left;
  font-size: 13px;
  line-height: 1.5;
  color: #595959;
  background-color: #fff;
  background-image: none;
  border: 1px solid #e9e9e9;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-transition: 4px;
  border-radius: 4px;
  -webkit-transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  -webkit-transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.react-tags-wrapper input:focus {
  outline: 0;
  border-color: #fd9648;
  box-shadow: 0 0 0 2px rgba(255, 237, 223, 0.2);
}
.react-tags-wrapper input::-webkit-input-placeholder {
  color: #b4b4b4;
}
.react-tags-wrapper input::-moz-placeholder {
  color: #b4b4b4;
}
.react-tags-wrapper input::-ms-input-placeholder {
  color: #b4b4b4;
}
.react-tags-wrapper input::placeholder {
  color: #b4b4b4;
}

.react-tags-wrapper input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #b4b4b4;
}

.react-tags-wrapper input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #b4b4b4;
}

.react-tags-wrapper .tag-wrapper {
  border: 1px solid #fd9648;
  padding: 5px 10px;
  border-radius: 20px;
  display: inline-block;
  margin-bottom: 5px;
  margin-right: 5px;
}

.react-tags-wrapper .tag-wrapper > a {
  color: #fd9648;
  margin-left: 5px;
}
/*----- END 5.2 Tag Input -----*/
/*----- 5.3 Switch -----*/
.switch-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}
/*----- END 5.3 Switch -----*/
/*----- 5.4 Timepicker -----*/
.ant-form-item .ant-time-picker {
  display: block;
  width: 100%;
}
/*----- END 5.4 Timepicker -----*/

/*----- 5.5 React-image-crop -----*/
.ant-modal-content .ReactCrop {
  background-color: transparent;
}
.ant-modal .ReactCrop__image {
  max-height: 100%;
}
/*----- END React-image-crop -----*/

/*----- 5.6 Table -----*/
.ant-table-footer .table-footer {
  margin-left: auto;
  width: auto;
}
.ant-table-footer .table-footer td {
  padding: 0 4px;
  text-align: right;
}
.ant-table-footer .table-footer td.label {
  font-weight: 500;
}
/*----- END 5.6 Table -----*/

/*----- 5.7 Date Range Picker -----*/
.DateRangePickerInput .DateRangePicker_picker {
  z-index: 10;
}
.DateRangePicker .container-custom-info {
  padding: 0.5rem;
}
.DateRangePicker .container-custom-info button {
  margin-bottom: 3px;
  margin-right: 3px;
}
/*----- END 5.7 Date Range Picker -----*/

/*===== END 5. PLUGINS =====*/

